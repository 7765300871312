import SearcherDestination from "./searcherDestination";
import SearcherDistribution from "./searcherDistribution";
import SearcherCalendar from "./searcherCalendar";
import SearcherValidation from "./searcherValidation";
import Searcher from "./searcher";
import SearcherSetCacheValues from "./searcherCacheValues";

export default class SearcherManager {
    constructor() {
        if ($('#form-searcher').length === 0) {
            return;
        }
        this.currentScrollPosition = 0;
        this.eventDelegation();
        this.searcher = new Searcher('form-searcher');
        this.searcherCache = new SearcherSetCacheValues();
        this.searcherValidation = new SearcherValidation(this.searcher, this.searcherCache);
        this.searcherDestination = new SearcherDestination();
        this.searcherDistribution = new SearcherDistribution(this.searcher, this.searcherValidation);
        this.searcherCalendar = new SearcherCalendar(this.searcher);
        if (this.searcherCalendar.checkSelectedDates()) {
            this.searcherCache.setCacheValues();
        } else {
            this.searcherCache.setCachedValues(this.searcherCalendar, this.searcherDistribution, this.searcherDestination);
            if (['xs','sm'].includes(myApp.deviceDetector.deviceSize)){
                this.searcherCalendar.bookingCalendarManager.resizeCalendar();
            }
        }
    }

    eventDelegation() {
        const searcherDropdown = $('[data-dropdown="searcher-dropdown"]');
        searcherDropdown.on('show.bs.dropdown', (event) => {
            const currentTarget = $(event.currentTarget);
            $('[data-entity="searcher-backdrop"]').show();
            let dropdownId = currentTarget.data('dropdown-id');

            if (currentTarget.attr('data-dropdown-id') === 'destination-dropdown') {
                const customDestinationText = document.querySelector('[name="hChollo"]');
                if (customDestinationText && customDestinationText.value) {
                    this.searcherDestination.setDestinationInputText();
                    customDestinationText.remove();
                    const searchedGroupId = document.querySelector('[name="searchedGroupId"]');
                    if (searchedGroupId) {
                        searchedGroupId.remove();
                    }
                    const groupByHotel = document.querySelector('[name="groupByHotel"]');
                    if (groupByHotel) {
                        groupByHotel.remove();
                    }
                    const searchedCholloId = document.querySelector('[name="searchedCholloId"]');
                    if (searchedCholloId) {
                        searchedCholloId.remove();
                    }
                }
            }
            myApp.browserHistoryManager.addState('[data-dropdown-id="' + dropdownId + '"]');
        });

        searcherDropdown.on('hide.bs.dropdown', (event) => {
            $('[data-entity="searcher-backdrop"]').hide();
            let dropdownId = $(event.currentTarget).data('dropdown-id');
            myApp.browserHistoryManager.removeState('[data-dropdown-id="' + dropdownId + '"]');
        });

        $('[data-searcher="open"]').on('click', () => {
            this.openSearcher();
            myApp.browserHistoryManager.addState('[data-entity="searcher-container"]');
        });

        $('[data-searcher="close"]').on('click', () => {
            myApp.browserHistoryManager.removeState('[data-entity="searcher-container"]');
        });

        $('[data-entity="searcher-container"]').on('pop-state', (event) => {
            this.closeSearcher();
            event.stopPropagation();
            event.preventDefault();
        });

        searcherDropdown.on('pop-state', (event) => {
            let currentTarget = $(event.currentTarget);
            if (currentTarget.hasClass('open')) {
                currentTarget.find('.dropdown-menu').dropdown('toggle');
                event.stopPropagation();
                event.preventDefault();
            }
        });
        if (myApp.deviceDetector.isMdScreen() || myApp.deviceDetector.isLgScreen()) {
            this.doSearchScroll();
        }
    }

    openSearcher() {
        this.currentScrollPosition = window.scrollY;
        $('[data-entity="searcher-container"]').addClass('open');
        $('body').addClass('searcher-opened');
    }

    closeSearcher() {
        $('body').removeClass('searcher-opened');
        window.scrollTo(0, this.currentScrollPosition);
        this.currentScrollPosition = 0;
        $('[data-entity="searcher-container"]').removeClass('open');
    }

    doSearchScroll() {
        let lastScrollTop = 0;
        let stickyContainer = $('[name="searcher"]');
        let stickyElement = $('[name="searcher-sticky-container"]');
        let sidebarElement = $('#sidebar');
        let scrollRang = 30;
        if (stickyContainer.length === 0 || stickyElement.length === 0) {
            return;
        }

        document.addEventListener('scroll', () => {
            let scrollTop = myApp.scrollManager.getScrollTop();
            if (!(scrollTop > (lastScrollTop + scrollRang) || scrollTop < (lastScrollTop - scrollRang))) {
                return;
            }

            let containerElementPosition = document.querySelector('[name="searcher"]').getBoundingClientRect();

            if (containerElementPosition.bottom < 0) {
                if (!stickyContainer.hasClass('search-sticky')) {
                    stickyElement.css('top', -(stickyElement.height() + 10));
                    stickyContainer.height(containerElementPosition.height);
                    stickyContainer.addClass('search-sticky')
                    $('[data-dropdown="searcher-dropdown"].open .dropdown-menu').dropdown('toggle');
                }
            } else {
                if (stickyContainer.hasClass('search-sticky')) {
                    stickyContainer.removeClass('search-sticky')
                    stickyElement.css('top', '');
                    stickyContainer.height('');
                    $('[data-dropdown="searcher-dropdown"].open .dropdown-menu').dropdown('toggle');
                }
            }
            if (stickyContainer.hasClass('search-sticky')) {
                if (scrollTop < lastScrollTop) {
                    stickyElement.css('top', '');
                    sidebarElement.css('top', sidebarElement.hasClass('affix-bottom') ? '' : (stickyElement.height() + 20));
                } else {
                    stickyElement.css('top', -(stickyElement.height() + 10));
                    sidebarElement.css('top', '');
                }
            }
            lastScrollTop = scrollTop;
        });
    }

    static get inputs() {
        return {
            formId: '#form-searcher',
            start: 'inicio',
            end: 'fin',
            bookingDates: '#booking-dates',
            adults: 'nA',
            children: 'nNe',
            childrenAges: 'edN',
            rooms: 'ha'
        }
    }
}
