import BookingCalendarManager from "@web/web-components/js/components/calendar/bookingCalendarManager";
import SearcherManager from "./searcherManager";

export default class SearcherCalendar {
    constructor(searcher) {
        this.searcher = searcher;
        let calendarData = JSON.parse(document.querySelector(SearcherManager.inputs.bookingDates).getAttribute('data'));
        this.bookingCalendarManager = new BookingCalendarManager(
            calendarData,
            myApp.config.locale,
            this.onOpenCalendar.bind(this),
            this.onCloseCalendar.bind(this),
            true,
            "d-m-Y",
            "inicio",
            "fin"
        );
        this.eventDelegation();
    }

    eventDelegation() {
        this.url = new URL(window.location.href);
        const openCalendar = this.url.searchParams.has('openCalendar') && !this.url.searchParams.has('userExclusive');

        $('#booking-dates').on('pop-state', (event) => {
            this.close();
            event.stopPropagation();
            event.preventDefault();
        });
        if (openCalendar){
            this.bookingCalendarManager.calendar.toggle()
            if (['xs','sm'].includes(myApp.deviceDetector.deviceSize)){
                this.bookingCalendarManager.resizeCalendar();
            }
        }

        const buttonsOpenCalendar = document.querySelectorAll('[data-action=calendar-open]');
        buttonsOpenCalendar.forEach( (button) => {
            button.addEventListener('click', (event) => {
                if (myApp.deviceDetector.isXsScreen() || myApp.deviceDetector.isMdScreen()) {
                    const searcherContainer = document.querySelector('[data-entity="searcher-container"]');
                    if (searcherContainer) {
                        searcherContainer.classList.add('open');
                        document.body.classList.add('searcher-opened');
                    }
                    this.bookingCalendarManager.getCalendar().open();
                } else {
                    this.openStickySearcher();
                }
            });
        });
    }

    onCloseCalendar() {
        myApp.browserHistoryManager.removeState('#booking-dates');
    }

    onOpenCalendar() {
        myApp.browserHistoryManager.addState('#booking-dates');
        document.querySelector(SearcherManager.inputs.bookingDates).dispatchEvent(new Event('openCalendar'));
    }

    close() {
        this.bookingCalendarManager.getCalendar().close();
    }

    getDatesObject(start, end) {
        if (!start || !end) {
            return false;
        }

        let startDate = start.split("-");
        let endDate = end.split("-");
        let startDateObject = new Date(startDate[1] + "/" + startDate[0] + "/" + startDate[2]);
        let endDateObject = new Date(endDate[1] + "/" + endDate[0] + "/" + endDate[2]);
        return [
            startDateObject,
            endDateObject
        ];
    }

    checkSelectedDates() {
        let datesObject = this.getDatesObject(
            this.searcher.getInputValue('start'),
            this.searcher.getInputValue('end')
        );
        if (datesObject) {
            this.setDates(datesObject[0], datesObject[1]);
            return true;
        }
        return false;
    }

    setDates(startDate, endDate) {
        this.bookingCalendarManager.calendar.element.selectedDates = [
            startDate,
            endDate,
        ];

        this.bookingCalendarManager.calendar.element.redraw();
        if (myApp.deviceDetector.isMdScreen() || myApp.deviceDetector.isLgScreen()){
            this.bookingCalendarManager.calendar.jumpToDate(startDate);
        }
        this.bookingCalendarManager.onChangeBookingDates(
            [startDate, endDate],
            null,
            this.bookingCalendarManager.calendar.element
        );
        this.bookingCalendarManager.calendar.setView();
    }

    openStickySearcher(){
        const stickyContainer = document.querySelector('[name="searcher"]');
        const stickyElement = document.querySelector('[name="searcher-sticky-container"]');
        const sidebarElement = document.querySelector('#sidebar');
        const containerElementPosition = document.querySelector('[name="searcher"]').getBoundingClientRect();

        stickyElement.style.top = `-${stickyElement.clientHeight + 10}px`;
        stickyContainer.style.height = `${containerElementPosition.clientHeight}px`;
        stickyContainer.classList.add('search-sticky');
        stickyElement.style.top = '';
        sidebarElement.style.top = sidebarElement.classList.contains('affix-bottom') ? '' : `${stickyElement.clientHeight + 20}px`;

        stickyElement.addEventListener("transitionend", () => {
            this.bookingCalendarManager.getCalendar().open();
        }, { once: true });
    }
}
