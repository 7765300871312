export default class SearcherDestination {
    constructor() {
        this.eventDelegation();
    }

    eventDelegation() {
        this.doSearchDestination();
    }

    doSearchDestination() {
        let destinations = $("[data-destination-option='true']");
        let allDestinations = $("[data-all-destinations='true']");
        let closeToMe = $("[data-close-to-me='true']");
        let newClass = 'active-item';
        destinations.on("click", (event) => {
            let currentElement = event.currentTarget;
            if (currentElement.hasAttribute("data-all-destinations") || currentElement.hasAttribute("data-close-to-me")) {
                destinations.removeClass(newClass);
            } else {
                allDestinations.removeClass(newClass);
                closeToMe.removeClass(newClass);
            }

            if (currentElement.classList.contains(newClass)) {
                currentElement.classList.remove(newClass);
            } else {
                currentElement.classList.add(newClass);
            }
            this.setDestinationInputText();
        });

        $('[data-dropdown-menu="destinations"]').on('click', function (event) {
            event.stopPropagation();
            event.stopImmediatePropagation();
            event.preventDefault();
            if ($(event.target).data('dropdown-button') === true ||
                $(event.target).closest('[data-dropdown-button="true"]').length === true) {
                $('[data-dropdown-menu="destinations"]').dropdown('toggle');
            }
        });

        $('.searcher-submit-button').on("click", (event) => {
            let selectedIds = this.loadSelectedDestinationsIds();
            jQuery.each(selectedIds, function() {
                let destinationInputCopy = $('#destination-input-template').contents().clone();
                destinationInputCopy.attr('value', this);
                if ($('.searcher-input-container').find('[value="' + this + '"].destination-input').length === 0) {
                    destinationInputCopy.appendTo('.searcher-input-container');
                }
            });
        });
    }

    loadSelectedDestinationsIds() {
        let selectedDestinations = $('.destination-dropdown').find('.destination-zone.active-item');
        return selectedDestinations.map(function() {
            return $( this ).attr('value');
        });
    }

    setDestinationInputText() {
        let allDestinations = $("[data-all-destinations='true']");
        let optionsSelected = $('.destination-dropdown').find('.active-item');
        if (optionsSelected.length > 1) {
            let destinationsInputText = "";
            jQuery.each(optionsSelected, function(index) {
                destinationsInputText += this.textContent.trim();
                if (index < optionsSelected.length - 1) {
                    destinationsInputText += ", ";
                }
            });
            $('#destination-text').text(destinationsInputText);
        } else if (optionsSelected.length > 0) {
            $('#destination-text').text(optionsSelected.text());
        } else {
            $('#destination-text').text(allDestinations.text());
            allDestinations.addClass('active-item');
        }
    }

    setDestinations(destinations) {
        $('[data-destination-option=true]').each(function() {
            $(this).removeClass("active-item");
        });
        let arrayDestinations = destinations.split(';');
        $.each(arrayDestinations, function (index, id) {
            $('[data-destination-option=true]').each(function() {
                if ($(this).attr('value') == id) {
                    $(this).addClass("active-item");
                }
            })
        });
        this.setDestinationInputText();
    }
}
